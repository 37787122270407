<template>
  <app-form-view
    app="audit"
    model="auditheader"
    api-url="audit/audit-header/"
    :title="$t('menu.audit')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    :prepare-data="prepareData"
    v-model="formData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditPeriod"
            rules="required"
            :label="$t('fields.auditPeriod')"
            :view="view"
            v-model="formData.audit_period"
            type="select"
            :items="[
              {
                label: 'Q1',
                value: 'q1'
              },
              {
                label: 'Q2',
                value: 'q2'
              },
              {
                label: 'Q3',
                value: 'q3'
              },
              {
                label: 'Q4',
                value: 'q4'
              },
              { label: 'Year End', value: 'year_end' },
              {
                label: 'Customs',
                value: 'customs'
              }
            ]"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_period"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditYear"
            rules="required"
            type="year-selection"
            :label="$t('fields.auditYear')"
            :view="view"
            v-model="formData.audit_year"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditYear"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_year"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="chartOfAccountHeaderID"
            rules="required"
            type="select-server"
            :label="$t('fields.chartOfAccountName')"
            :view="view"
            :binds="{
              apiUrl: 'chart-of-account/chart-of-account-header/?active=true'
            }"
            v-model="formData.chart_of_account_header_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveChartOfAccountHeaderID"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_chart_of_account_header_id"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="contactID"
            rules="required"
            type="select-server"
            :label="$t('fields.contactName')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.contact_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveContactID"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact_id"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="businessIndustry"
            type="select-server"
            rules="required"
            :label="$t('fields.businessIndustry')"
            :view="view"
            :binds="{
              apiUrl:
                'doc-template/industry/?active=true' +
                '&contact_id=' +
                formData.contact_id.id
            }"
            v-model="formData.industry_id"
            @input="getBusinessCateItem"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveBusinessIndustry"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_business_industry"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="businessCategory"
            type="select-server"
            rules="required"
            :label="$t('fields.businessCategory')"
            :view="view"
            :binds="{
              apiUrl: categoryUrl
            }"
            v-model="formData.business_cate_id"
            @input="getBusinessTypeItem"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveBusinessCategory"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_business_category"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="businessType"
            type="select-server"
            rules="required"
            :label="$t('fields.businessType')"
            :view="view"
            :binds="{
              apiUrl: buztypeUrl
            }"
            v-model="formData.business_type_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveBusinessType"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_business_type"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Invoice"
            :label="$t('fields.invoice')"
            :view="view"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveInvoice"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_invoice"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="invoiceItem"
            :label="$t('fields.invoiceItem')"
            :view="view"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveInvoiceItem"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_invoice_item"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditPlan"
            rules="required"
            type="select-server"
            :label="$t('fields.auditPlan')"
            :view="view"
            :binds="{
              apiUrl: 'audit/audit-plan-header/'
            }"
            v-model="formData.audit_plan_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditPlan"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_plan_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="preparedBy"
            type="select-server"
            :label="$t('fields.preparedBy')"
            :view="view"
            :binds="{
              apiUrl: `contact/contact/?active=true&audit_header_id=${auditHeaderId}`
            }"
            v-model="formData.prepare_by"
          />

          <app-input
            v-if="isSentToApprove"
            name="approvePreparedBy"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_prepare_by"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="prepareDate"
            type="datepicker"
            :label="$t('fields.prepareDate')"
            :view="view"
            v-model="formData.prepare_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePrepareDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_prepare_date"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewedBy"
            type="select-server"
            :label="$t('fields.reviewedBy')"
            :view="view"
            :binds="{
              apiUrl: `contact/contact/?active=true&audit_header_id=${auditHeaderId}`
            }"
            v-model="formData.review_by"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReviewedBy"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_review_by"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewDate"
            type="datepicker"
            :label="$t('fields.reviewDate')"
            :view="view"
            v-model="formData.review_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReviewDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_review_date"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="startDate"
            rules="required"
            type="datepicker"
            :label="$t('fields.startDate')"
            :view="view"
            v-model="formData.start_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveStartDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_start_date"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="endDate"
            rules="required"
            type="datepicker"
            :label="$t('fields.endDate')"
            :view="view"
            v-model="formData.end_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveEndDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_end_date"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodBeginning"
            type="datepicker"
            :label="$t('fields.accountingPeriodBeginning')"
            :view="view"
            v-model="formData.accounting_period_beginning"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodBeginning"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_beginning"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodEnding"
            type="datepicker"
            :label="$t('fields.accountingPeriodEnding')"
            :view="view"
            v-model="formData.accounting_period_ending"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodEnding"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_ending"
          />
        </v-col>
      </v-row>

      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>{{ $t('menu.auditPlan') }}</v-tab>
          <v-tab>{{ $t('menu.preAudit') }}</v-tab>
          <v-tab>{{ $t('menu.audit') }}</v-tab>
          <v-tab>{{ $t('menu.postAudit') }}</v-tab>
          <v-tab>{{ $t('menu.activityMatrix') }}</v-tab>
          <v-tab>{{ $t('menu.riskMatrix') }}</v-tab>
          <v-tab>{{ $t('menu.member') }}</v-tab>
          <v-tab>{{ $t('menu.timesheetByMember') }}</v-tab>
          <v-tab>{{ $t('menu.timesheetByPosition') }}</v-tab>
          <v-tab>{{ $t('menu.trialBalance') }}</v-tab>
          <v-tab>{{ $t('menu.adjAndRecSummary') }}</v-tab>
          <v-tab>{{ $t('menu.trialBalanceBeforeAudit') }}</v-tab>
          <v-tab>{{ $t('menu.trialBalanceAfterAudit') }}</v-tab>
          <v-tab>{{ $t('menu.financialReport') }}</v-tab>
          <v-tab>{{ $t('menu.controlPL') }}</v-tab>
          <v-tab>{{ $t('menu.report') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Audit Program Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  hide-delete
                  app="audit"
                  model="task"
                  :headers="auditPlanItemHeader"
                  :serverItems="auditPlanItemItems"
                  :clientItems="auditPlanItemItems"
                  :loading="loading"
                  :server-items-length="auditPlanItemServerItemsLength"
                  :isSelecteable="false"
                  @server="getAuditPlanItemItems"
                  @delete="onDeleteAuditTaskItemItem"
                  @edit="onEditTask"
                  @view="onEditTask"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Audit Plan</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog
                            v-model="dialogAuditPlanItem"
                            max-width="1500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteAuditTaskItemItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>
                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card flat>
                              <v-card-text class="pt-4">
                                <v-toolbar dense color="primary white--text">
                                  <v-toolbar-title>
                                    {{ $t('menu.suggestionAuditProgram') }}
                                  </v-toolbar-title>
                                </v-toolbar>
                                <v-row class="mt-4">
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      name="company"
                                      type="select-server"
                                      :label="$t('fields.selectCompany')"
                                      :binds="{
                                        apiUrl:
                                          'contact/contact/?state=approved&active=true'
                                      }"
                                      :view="view"
                                      v-model="editedAuditPlan.contact_id"
                                    />
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <app-input
                                      name="suggestType"
                                      type="select"
                                      :label="$t('fields.suggestType')"
                                      :binds="{
                                        apiUrl:
                                          'doc-template/document/?active=true'
                                      }"
                                      :items="[
                                        {
                                          label: 'Suggest',
                                          value: 'suggest'
                                        },
                                        {
                                          label: 'Display All',
                                          value: 'display_all'
                                        }
                                      ]"
                                      v-model="editedAuditPlan.suggest_type"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row
                                  v-if="
                                    editedAuditPlan.suggest_type !== null &&
                                    editedAuditPlan.suggest_type !== undefined
                                  "
                                >
                                  <v-col cols="12" sm="12">
                                    <app-table
                                      server-side
                                      app="audit"
                                      model="auditplanheader"
                                      :headers="suggestionHeader"
                                      :serverItems="suggestionItemItems"
                                      :clientItems="suggestionItemItems"
                                      :loading="loading"
                                      :server-items-length="
                                        suggestionItemServerItemsLength
                                      "
                                      v-model="selectedSuggest"
                                      @server="getSuggestion"
                                    />
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeAuditPlanItem"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveAuditPlanItem"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Pre Audit Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="taskItemHeader"
                  :serverItems="preAuditTasks"
                  :clientItems="preAuditTasks"
                  :loading="loading"
                  :server-items-length="preAuditTasksLength"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  v-model="selectedTasks"
                  @server="
                    () => getTasks('task_template_header_id__is_pre_audit')
                  "
                  @delete="(item) => onDeleteTask(item, 'is_pre_audit')"
                  @edit="onEditTask"
                  @view="onEditTask"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>{{
                            $t('menu.preAudit')
                          }}</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <slot name="actions">
                            <slot name="prefix-actions" />
                            <v-btn
                              color="primary"
                              dark
                              v-show="mode === 'edit'"
                              @click="onCreateTask"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              class="ml-2"
                              v-show="selectedTasks.length && mode === 'edit'"
                              color="error"
                              @click="
                                onDeleteTask(selectedTasks, 'is_pre_audit')
                              "
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                            <slot name="suffix-actions" />
                          </slot>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Audit Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="taskItemHeader"
                  :serverItems="auditTasks"
                  :clientItems="auditTasks"
                  :loading="loading"
                  :server-items-length="auditTasksLength"
                  :hide-delete="mode !== 'edit'"
                  :hide-edit="mode !== 'edit'"
                  v-model="selectedTasks"
                  @server="() => getTasks('task_template_header_id__is_audit')"
                  @delete="(item) => onDeleteTask(item, 'is_audit')"
                  @edit="onEditTask"
                  @view="onEditTask"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>{{
                            $t('menu.audit')
                          }}</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <slot name="actions">
                            <slot name="prefix-actions" />
                            <v-btn
                              color="primary"
                              dark
                              v-show="mode === 'edit'"
                              @click="onCreateTask"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              class="ml-2"
                              v-show="selectedTasks.length && mode === 'edit'"
                              color="error"
                              @click="onDeleteTask(selectedTasks, 'is-audit')"
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                            <slot name="suffix-actions" />
                          </slot>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Post Audit Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="taskItemHeader"
                  :serverItems="postAuditTasks"
                  :clientItems="postAuditTasks"
                  :loading="loading"
                  :server-items-length="postAuditTasksLength"
                  :hide-delete="mode !== 'edit'"
                  :hide-edit="mode !== 'edit'"
                  v-model="selectedTasks"
                  @server="
                    () => getTasks('task_template_header_id__is_post_audit')
                  "
                  @delete="(item) => onDeleteTask(item, 'is_post_audit')"
                  @edit="onEditTask"
                  @view="onEditTask"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>{{
                            $t('menu.postAudit')
                          }}</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <slot name="actions">
                            <slot name="prefix-actions" />
                            <v-btn
                              color="primary"
                              dark
                              v-show="mode === 'edit'"
                              @click="onCreateTask"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              class="ml-2"
                              v-show="selectedTasks.length && mode === 'edit'"
                              color="error"
                              @click="
                                onDeleteTask(selectedTasks, 'is_post_audit')
                              "
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                            <slot name="suffix-actions" />
                          </slot>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Activity Matrix Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="auditplanactivitymatrix"
                  hide-edit
                  hide-delete
                  :headers="activityMatrixItemHeader"
                  :serverItems="activityMatrixItemItems"
                  :clientItems="activityMatrixItemItems"
                  :loading="loading"
                  :server-items-length="activityMatrixItemServerItemsLength"
                  :isSelecteable="false"
                  @server="getActivityMatrixItemItems"
                  @view="onViewActivityTask"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Activity Matrix</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogActivityMatrix"
                            max-width="1500px"
                          >
                            <v-card>
                              <v-card-text class="pt-4">
                                <v-toolbar-title>Task</v-toolbar-title>
                                <v-divider
                                  class="mx-4"
                                  inset
                                  vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <app-table
                                  server-side
                                  app="audit"
                                  model="task"
                                  :headers="activityTaskItemHeader"
                                  :serverItems="activityTaskItemItems"
                                  :clientItems="activityTaskItemItems"
                                  :loading="loading"
                                  :server-items-length="
                                    activityTaskItemServerItemsLength
                                  "
                                  :isSelecteable="false"
                                  @server="getActivityTask"
                                />
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeActivityMatrix"
                                >
                                  Close
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Risk Matrix Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="auditplanriskmatrix"
                  hide-edit
                  hide-delete
                  :headers="riskMatrixItemHeader"
                  :serverItems="riskMatrixItemItems"
                  :clientItems="riskMatrixItemItems"
                  :loading="loading"
                  :server-items-length="riskMatrixItemServerItemsLength"
                  :isSelecteable="false"
                  @server="getRiskMatrixItemItems"
                  @view="onViewRiskTask"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Risk Matrix</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRiskMatrix"
                            max-width="1500px"
                          >
                            <v-card>
                              <v-card-text class="pt-4">
                                <v-toolbar-title>Task</v-toolbar-title>
                                <v-divider
                                  class="mx-4"
                                  inset
                                  vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <app-table
                                  server-side
                                  app="audit"
                                  model="task"
                                  :headers="riskTaskItemHeader"
                                  :serverItems="riskTaskItemItems"
                                  :clientItems="riskTaskItemItems"
                                  :loading="loading"
                                  :server-items-length="
                                    riskTaskItemServerItemsLength
                                  "
                                  :isSelecteable="false"
                                  @server="getRiskTask"
                                />
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeRiskMatrix"
                                >
                                  Close
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Member Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="auditplanmember"
                  :headers="memberItemHeader"
                  :serverItems="memberItems"
                  :clientItems="memberItems"
                  :loading="loading"
                  :server-items-length="memberItemsLength"
                  :hide-delete="mode !== 'edit'"
                  :hide-edit="mode !== 'edit'"
                  v-model="selectedMemberItems"
                  @server="getMemberItems"
                  @delete="onDeleteMemberItem"
                  @edit="onEditMemberItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Member</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogMemberItem"
                            max-width="900px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  class="ml-2"
                                  v-show="
                                    selectedMemberItems.length &&
                                    mode === 'edit'
                                  "
                                  color="error"
                                  @click="
                                    onDeleteMemberItem(selectedMemberItems)
                                  "
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container v-if="editId === null">
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        name="typeMember"
                                        type="select"
                                        :label="$t('fields.type')"
                                        :view="view"
                                        v-model="editedMemberItem.type_member"
                                        :events="{
                                          change: onChangeTypeMember
                                        }"
                                        :items="[
                                          {
                                            label: 'Team',
                                            value: 'team'
                                          },
                                          {
                                            label: 'Member',
                                            value: 'member'
                                          }
                                        ]"
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="
                                      editedMemberItem.type_member === 'team'
                                    "
                                  >
                                    <v-col cols="12" sm="12">
                                      <app-table
                                        app="team"
                                        model="teamheader"
                                        :headers="teamItemHeader"
                                        :clientItems="teamItems"
                                        :loading="loading"
                                        :hide-delete="mode !== 'edit'"
                                        :hide-edit="mode !== 'edit'"
                                        v-model="selectedTeamEmpItems"
                                      />
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="
                                      editedMemberItem.type_member === 'member'
                                    "
                                  >
                                    <v-col cols="12" sm="12">
                                      <app-table
                                        app="contact"
                                        model="contact"
                                        :headers="empItemHeader"
                                        :clientItems="empItems"
                                        :loading="loading"
                                        v-model="selectedTeamEmpItems"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                                <v-container v-else>
                                  <v-row>
                                    <v-col cols="12" sm="12">
                                      <app-input
                                        name="position"
                                        :label="$t('fields.position')"
                                        :view="view"
                                        type="select-server"
                                        v-model="editedMemberItem.position"
                                        :binds="{
                                          apiUrl:
                                            'audit/master-position/?active=true&state=approved'
                                        }"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeMemberItem"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveMemberItem"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- timesheet by member Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="timesheetByMemberHeaders"
                  :serverItems="timesheetByMembers"
                  :loading="loading"
                  :server-items-length="timesheetByMembersCount"
                  hide-delete
                  hide-edit
                  :is-selecteable="false"
                  @server="getTimesheetByMembers"
                  :is-expand-server="true"
                  :single-expand="true"
                  :expand-server-headers="timesheetByMemberIdHeaders"
                  :expand-items="timesheetByMemberIdItems"
                  :expand-items-length="timesheetByMemberIdItemsCount"
                  @item-expanded="getTimesheetByMemberIdItems"
                  sub-item-key="member_id"
                  item-key="member_id"
                >
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByMemberSumAssignedManDay
                            )
                          }}
                        </td>
                        <td>
                          {{ percentFormat(timesheetByMemberSumWeight) }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByMemberSumFinishedAssignedManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(timesheetByMemberSumActualManDay)
                          }}
                        </td>
                        <td>
                          {{ hoursMinutesFormat(timesheetByMemberSumVariance) }}
                        </td>
                        <td>
                          {{
                            percentFormat(timesheetByMemberSumTaskPerformance)
                          }}
                        </td>
                        <td>
                          {{ percentFormat(timesheetByMemberSumWorkProgress) }}
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- timesheet by position Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="audit"
                  model="task"
                  :headers="timesheetByPositionHeaders"
                  :serverItems="timesheetByPositions"
                  :loading="loading"
                  :server-items-length="timesheetByPositionsCount"
                  hide-delete
                  hide-edit
                  :is-selecteable="false"
                  @server="getTimesheetByPositions"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >Timesheet By Position</v-toolbar-title
                          >
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>

                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByPositionSumStandardManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByPositionSumAssignedManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(timesheetByPositionSumUnassigned)
                          }}
                        </td>
                        <td>
                          {{
                            percentFormat(
                              timesheetByPositionSumAssignedProgress
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByPositionSumFinishedAssignedManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(
                              timesheetByPositionSumActualManDay
                            )
                          }}
                        </td>
                        <td>
                          {{
                            hoursMinutesFormat(timesheetByPositionSumVariance)
                          }}
                        </td>
                        <td>
                          {{
                            percentFormat(timesheetByPositionSumTaskPerformance)
                          }}
                        </td>
                        <td>
                          {{
                            percentFormat(timesheetByPositionSumWorkProgress)
                          }}
                        </td>
                        <td>
                          {{ percentFormat(timesheetByPositionSumWeight) }}
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Trial Balance Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <!-- Select Trial Balance  -->
                <v-row class="mb-2">
                  <v-col cols="3" lg="6" class="app-input--approve-wrapper">
                    <app-input
                      name="trialBalanceHeader"
                      type="select-server"
                      :label="$t('fields.trialBalance')"
                      :view="view"
                      :binds="{
                        apiUrl: `trial-balance/trial-balance-header/?contact_id=${formData.contact_id.id}&state=approved`
                      }"
                      v-model="formData.trial_balance_header_id"
                      :events="{
                        change: onChangeTrialBalance
                      }"
                    />
                  </v-col>
                </v-row>
                <!-- Table Trial Balance -->

                <app-table
                  server-side
                  ref="trialBalanceTable"
                  app="audit_trial_balance"
                  model="trialbalanceheader"
                  :hide-edit="true"
                  :hide-delete="true"
                  :isSelecteable="false"
                  :headers="trialBalanceHeaders"
                  :serverItems="trialBalanceItems"
                  :clientItems="trialBalanceItems"
                  :server-items-length="trialBalanceItemsLength"
                  :loading="loading"
                  @server="getTrialBalanceItems"
                  @client="getTrialBalanceClientItems"
                >
                  <template
                    slot="body.append"
                    v-if="trialBalanceItemsLength > 0"
                  >
                    <tr>
                      <td></td>
                      <td></td>
                      <td class="text-subtitle-1">Totals</td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumBeginningBalance) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumDebit) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumCredit) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumEndingBalance) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumCalEndingBalance) }}
                      </td>
                      <td class="text-subtitle-1 text-right">
                        {{ accountFieldFormat(sumDiffEndingBalance) }}
                      </td>
                    </tr>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Adj. and Rec. Summary Tab -->
          <v-tab-item>
            <v-toolbar flat>
              <v-toolbar-title
                >Adjustment and Reconcile Summary</v-toolbar-title
              >
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  model="task"
                  app="task"
                  :headers="adjSumHeaders"
                  hide-edit
                  hide-delete
                  :server-items="adjSumItems"
                  :server-items-length="adjSumItemsCount"
                  @server="getAdjSumItems"
                >
                  <template v-slot:item.link="{ item }">
                    <v-btn icon @click="showAdjSumDialog(item)">
                      <v-icon color="primary">mdi-link</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td>
                          {{ accountingFormat(adjSumGrandTotalDebit) }}
                        </td>
                        <td>
                          {{ accountingFormat(adjSumGrandTotalCredit) }}
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
            <v-dialog v-model="dialogAdjSum" max-width="700px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          ref="adjSumLineTable"
                          server-side
                          model="task"
                          app="audit"
                          :headers="adjSumLineHeaders"
                          hide-edit
                          hide-delete
                          :server-items="adjSumLines"
                          :server-items-length="adjSumLinesCount"
                          @server="getAdjSumLines"
                        >
                          <template
                            v-slot:item.task_adjustment__task__id="{ item }"
                          >
                            <router-link
                              :to="{
                                name: 'taskEdit',
                                params: { id: item.task_adjustment__task__id }
                              }"
                              target="_blank"
                            >
                              <v-icon color="primary">mdi-link</v-icon>
                            </router-link>
                          </template>
                        </app-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onAdjSumLinesClose">
                    {{ $t('btn.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <!-- Trialbalance Before Audit Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="trialBalanceTable"
                  app="audit_trial_balance"
                  model="trialbalanceheader"
                  :hide-edit="true"
                  :hide-delete="true"
                  :isSelecteable="false"
                  :headers="adjustmentLineHeaders"
                  :serverItems="adjustments"
                  :clientItems="adjustments"
                  :server-items-length="adjustmentsCount"
                  :loading="loading"
                  @server="getAdjustments"
                >
                  <template v-slot:item.link="{ item }">
                    <v-btn icon @click="showAdjDialog(item)">
                      <v-icon color="primary">mdi-link</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td>
                          {{ accountingFormat(adjGrandEndingBalance) }}
                        </td>
                        <td>
                          {{ accountingFormat(adjGrandTotalDebit) }}
                        </td>
                        <td>
                          {{ accountingFormat(adjGrandTotalCredit) }}
                        </td>
                        <td>
                          {{ accountingFormat(adjGrandAuditEndingBalance) }}
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
            <v-dialog v-model="dialogAdj" max-width="900px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          ref="adjLineTable"
                          server-side
                          model="task"
                          app="audit"
                          :headers="adjLineHeaders"
                          hide-edit
                          hide-delete
                          :server-items="adjLines"
                          :server-items-length="adjLinesCount"
                          @server="getAdjLines"
                        >
                          <template
                            v-slot:item.task_adjustment__task__id="{ item }"
                          >
                            <router-link
                              :to="{
                                name: 'taskEdit',
                                params: { id: item.task_adjustment__task__id }
                              }"
                              target="_blank"
                            >
                              <v-icon color="primary">mdi-link</v-icon>
                            </router-link>
                          </template>
                        </app-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="onAdjLinesClose">
                    {{ $t('btn.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <!-- Trialbalance After Audit Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="trialBalanceTable"
                  app="audit_trial_balance"
                  model="trialbalanceheader"
                  :hide-edit="true"
                  :hide-delete="true"
                  :isSelecteable="false"
                  :headers="adjustmentLineHeaders"
                  :serverItems="adjustmentsAfter"
                  :clientItems="adjustmentsAfter"
                  :server-items-length="adjustmentsAfterCount"
                  :loading="loading"
                  @server="getAdjustmentsAfter"
                >
                  <template v-slot:item.link="{ item }">
                    <v-btn icon @click="showAdjAfterDialog(item)">
                      <v-icon color="primary">mdi-link</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td>
                          {{ accountingFormat(adjAfterGrandEndingBalance) }}
                        </td>
                        <td>
                          {{ accountingFormat(adjAfterGrandTotalDebit) }}
                        </td>
                        <td>
                          {{ accountingFormat(adjAfterGrandTotalCredit) }}
                        </td>
                        <td>
                          {{
                            accountingFormat(adjAfterGrandAuditEndingBalance)
                          }}
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
            <v-dialog v-model="dialogAdjAfter" max-width="900px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <app-table
                          ref="adjAfterLineTable"
                          server-side
                          model="task"
                          app="audit"
                          :headers="adjLineHeaders"
                          hide-edit
                          hide-delete
                          :server-items="adjAfterLines"
                          :server-items-length="adjAfterLinesCount"
                          @server="getAdjAfterLines"
                        >
                          <template
                            v-slot:item.task_adjustment__task__id="{ item }"
                          >
                            <router-link
                              :to="{
                                name: 'taskEdit',
                                params: { id: item.task_adjustment__task__id }
                              }"
                              target="_blank"
                            >
                              <v-icon color="primary">mdi-link</v-icon>
                            </router-link>
                          </template>
                        </app-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="onAdjAfterLinesClose"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <!-- Financial Report Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-dialog v-model="dialogPrintBalanceSheet" max-width="1500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('menu.balanceSheet') }}
                    </v-btn>
                  </template>
                  <v-card flat>
                    <v-card-text class="pt-4">
                      <v-row class="mt-4">
                        <v-col cols="12" sm="6" md="4">
                          <app-input
                            name="currentYearPreferredStock"
                            :rules="{
                              required:
                                !isPublicCompany &&
                                !isLimitedPartnership &&
                                !isForeign &&
                                !isJointVenture
                            }"
                            :label="$t('fields.currentYearPreferredStock')"
                            :view="false"
                            :disabled="
                              isPublicCompany ||
                              isLimitedPartnership ||
                              isForeign ||
                              isJointVenture
                            "
                            v-model="
                              printBalanceSheet.currentYearPreferredStock
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <app-input
                            name="lastYearPreferredStock"
                            :rules="{
                              required:
                                !isPublicCompany &&
                                !isLimitedPartnership &&
                                !isForeign &&
                                !isJointVenture
                            }"
                            :label="$t('fields.lastYearPreferredStock')"
                            :view="false"
                            :disabled="
                              isPublicCompany ||
                              isLimitedPartnership ||
                              isForeign ||
                              isJointVenture
                            "
                            v-model="printBalanceSheet.lastYearPreferredStock"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mt-4">
                        <v-col cols="12" sm="6" md="4">
                          <app-input
                            name="currentYearCommonStock"
                            :rules="{
                              required:
                                !isPublicCompany &&
                                !isLimitedPartnership &&
                                !isForeign &&
                                !isJointVenture
                            }"
                            :label="$t('fields.currentYearCommonStock')"
                            :view="false"
                            :disabled="
                              isPublicCompany ||
                              isLimitedPartnership ||
                              isForeign ||
                              isJointVenture
                            "
                            v-model="printBalanceSheet.currentYearCommonStock"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <app-input
                            name="lastYearCommonStock"
                            :rules="{
                              required:
                                !isPublicCompany &&
                                !isLimitedPartnership &&
                                !isForeign &&
                                !isJointVenture
                            }"
                            :label="$t('fields.lastYearCommonStock')"
                            :view="false"
                            :disabled="
                              isPublicCompany ||
                              isLimitedPartnership ||
                              isForeign ||
                              isJointVenture
                            "
                            v-model="printBalanceSheet.lastYearCommonStock"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mt-4">
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          class="app-input--approve-wrapper"
                        >
                          <app-input
                            name="lastYearTrialBalanceId"
                            type="select-server"
                            :label="$t('fields.lastYearTrialBalance')"
                            :view="false"
                            :binds="{
                              apiUrl: `trial-balance/trial-balance-header/?contact_id=${formData.contact_id.id}&state=approved`
                            }"
                            v-model="printBalanceSheet.lastYearTrialBalanceId"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          class="app-input--approve-wrapper"
                        >
                          <app-input
                            name="lastYearAuditId"
                            type="select-server"
                            :label="$t('fields.lastYearAudit')"
                            :view="false"
                            :binds="{
                              apiUrl: `audit/audit-header/?contact_id=${formData.contact_id.id}&state=approved`
                            }"
                            v-model="printBalanceSheet.lastYearAuditId"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onPrintBalanceSheetCancel"
                      >
                        {{ $t('btn.cancel') }}
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onPrintBalanceSheetConfirm"
                      >
                        {{ $t('btn.printBalanceSheet') }}
                      </v-btn></v-card-actions
                    >
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogPrintProfitAndLoss" max-width="1500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('menu.profitAndLoss') }}
                    </v-btn>
                  </template>
                  <v-card flat>
                    <v-card-text class="pt-4">
                      <v-row class="mt-4">
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          class="app-input--approve-wrapper"
                        >
                          <app-input
                            name="lastYearTrialBalanceId"
                            type="select-server"
                            :label="$t('fields.lastYearTrialBalance')"
                            :view="false"
                            :binds="{
                              apiUrl: `trial-balance/trial-balance-header/?contact_id=${formData.contact_id.id}&state=approved`
                            }"
                            v-model="printProfitAndLoss.lastYearTrialBalanceId"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          class="app-input--approve-wrapper"
                        >
                          <app-input
                            name="lastYearAuditId"
                            type="select-server"
                            :label="$t('fields.lastYearAudit')"
                            :view="false"
                            :binds="{
                              apiUrl: `audit/audit-header/?contact_id=${formData.contact_id.id}&state=approved`
                            }"
                            v-model="printProfitAndLoss.lastYearAuditId"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onPrintProfitAndLossCancel"
                      >
                        {{ $t('btn.cancel') }}
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onPrintProfitAndLossConfirm"
                      >
                        {{ $t('btn.printProfitAndLoss') }}
                      </v-btn></v-card-actions
                    >
                  </v-card>
                </v-dialog>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Control PL Tab -->
          <v-tab-item> </v-tab-item>
          <!-- Report -->
          <v-tab-item
            ><v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="conclusionReportTable"
                  app="audit_trial_balance"
                  model="trialbalanceheader"
                  :hide-edit="true"
                  :hide-delete="true"
                  :isSelecteable="false"
                  :headers="conclusionReportHeaders"
                  :serverItems="conclusionReport"
                  :clientItems="conclusionReport"
                  :server-items-length="conclusionReportCount"
                  :loading="loading"
                  @server="getReport"
                  @view="onEditTaskReport"
                >
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '@components/AppTable.vue'
import { defaultTableParams } from '@utils/app-table'
import { accounting, hoursMinutes, percentFormat } from '@utils/number-format'

export default {
  name: 'audit-plan-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      tab: null,
      loading: false,
      approveItems: [
        'approve_name',
        'approve_audit_period',
        'approve_audit_year',
        'approve_chart_of_account_header_id',
        'approve_contact_id',
        'approve_business_industry',
        'approve_business_category',
        'approve_business_type',
        'approve_invoice',
        'approve_invoice_item',
        'approve_audit_plan_id',
        'approve_prepare_by',
        'approve_prepare_date',
        'approve_review_by',
        'approve_review_date',
        'approve_start_date',
        'approve_end_date',
        'approve_accounting_period_beginning',
        'approve_accounting_period_ending'
      ],
      editId: null,
      apiMethod: 'post',
      trialBalanceItems: [],
      trialBalanceClientItems: [],
      trialBalanceItemsLength: 0,
      selected: [],
      selectedTasks: [],
      preAuditTasks: [],
      preAuditTasksLength: 0,
      auditTasks: [],
      auditTasksLength: 0,
      postAuditTasks: [],
      postAuditTasksLength: 0,
      memberItems: [],
      memberItemsLength: 0,
      dialogMemberItem: false,
      editedMemberItem: {},
      selectedMemberItems: [],
      teamItems: [],
      empItems: [],
      selectedTeamEmpItems: [],
      adjSumItems: [],
      adjSumItemsCount: 0,
      adjSumGrandTotalDebit: 0,
      adjSumGrandTotalCredit: 0,
      dialogAdjSum: false,
      editingAdjSum: null,
      adjSumLines: [],
      adjSumLinesCount: 0,
      editedAuditPlan: [],
      adjItems: [],
      adjItemsCount: 0,
      adjGrandTotalDebit: 0,
      adjGrandTotalCredit: 0,
      dialogAdj: false,
      editingAdj: null,
      adjLines: [],
      adjLinesCount: 0,
      dialogAuditPlanItem: false,
      auditPlanItemItems: [],
      auditPlanItemServerItemsLength: 0,
      selectedSuggest: [],
      suggestionItemItems: [],
      suggestionItemServerItemsLength: 0,
      dialogActivityMatrix: false,
      activityMatrixProfileId: null,
      activityMatrixItemItems: [],
      activityMatrixItemServerItemsLength: 0,
      activityTaskItemItems: [],
      activityTaskItemServerItemsLength: 0,
      dialogRiskMatrix: false,
      riskMatrixProfileId: null,
      riskMatrixItemItems: [],
      riskMatrixItemServerItemsLength: 0,
      riskTaskItemItems: [],
      riskTaskItemServerItemsLength: 0,
      timesheetByPositions: [],
      timesheetByPositionsCount: 0,
      timesheetByPositionSumStandardManDay: 0,
      timesheetByPositionSumAssignedManDay: 0,
      timesheetByPositionSumUnassigned: 0,
      timesheetByPositionSumAssignedProgress: 0,
      timesheetByPositionSumFinishedAssignedManDay: 0,
      timesheetByPositionSumActualManDay: 0,
      timesheetByPositionSumVariance: 0,
      timesheetByPositionSumTaskPerformance: 0,
      timesheetByPositionSumWorkProgress: 0,
      timesheetByPositionSumWeight: 0,
      editingAdjustment: {},
      editingAdjustmentLine: {},
      adjustments: [],
      adjustmentsCount: 0,
      adjustmentsAfter: [],
      adjustmentsAfterCount: 0,
      adjGrandEndingBalance: 0,
      adjGrandAuditEndingBalance: 0,
      timesheetByMembers: [],
      timesheetByMembersCount: 0,
      timesheetByMemberSumStandardManDay: 0,
      timesheetByMemberSumAssignedManDay: 0,
      timesheetByMemberSumWeight: 0,
      timesheetByMemberSumFinishedAssignedManDay: 0,
      timesheetByMemberSumActualManDay: 0,
      timesheetByMemberSumVariance: 0,
      timesheetByMemberSumTaskPerformance: 0,
      timesheetByMemberSumWorkProgress: 0,
      timesheetByMemberIdItems: {},
      timesheetByMemberIdItemsCount: {},
      conclusionReport: [],
      conclusionReportCount: 0,
      adjAfterItems: [],
      adjAfterItemsCount: 0,
      adjAfterGrandTotalDebit: 0,
      adjAfterGrandTotalCredit: 0,
      dialogAdjAfter: false,
      editingAdjAfter: null,
      adjAfterLines: [],
      adjAfterLinesCount: 0,
      adjAfterGrandEndingBalance: 0,
      adjAfterGrandAuditEndingBalance: 0,
      dialogPrintBalanceSheet: false,
      printBalanceSheet: {},
      dialogPrintProfitAndLoss: false,
      printProfitAndLoss: {},
      isPublicCompany: false,
      isLimitedPartnership: false,
      isForeign: false,
      isJointVenture: false,
      categoryUrl: null,
      buztypeUrl: null,
      auditHeaderId: null
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    auditPlanItemHeader() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.reference'),
          value: 'reference',
          hideFilter: true
        },
        {
          text: this.$t('fields.taskName'),
          value: 'name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('label.preAudit'),
          value: 'task_template_header_id.is_pre_audit',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.audit'),
          value: 'task_template_header_id.is_audit',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.postAudit'),
          value: 'task_template_header_id.is_post_audit',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    riskMatrixItemHeader() {
      return [
        {
          text: this.$t('fields.riskProfile'),
          value: 'name',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.task'),
          value: 'audit_risk_profile_task_count',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    riskTaskItemHeader() {
      return [
        {
          text: this.$t('fields.reference'),
          value: 'reference',
          hideFilter: true
        },
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.sectionName'),
          value: 'section_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('label.preAudit'),
          value: 'is_pre_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('label.audit'),
          value: 'is_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('label.postAudit'),
          value: 'is_post_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        }
      ]
    },
    activityMatrixItemHeader() {
      return [
        {
          text: this.$t('fields.activityProfile'),
          value: 'name',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.task'),
          value: 'audit_activity_profile_task_count',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    activityTaskItemHeader() {
      return [
        {
          text: this.$t('fields.reference'),
          value: 'reference',
          hideFilter: true
        },
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.sectionName'),
          value: 'section_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('label.preAudit'),
          value: 'is_pre_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('label.audit'),
          value: 'is_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('label.postAudit'),
          value: 'is_post_audit',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        }
      ]
    },
    suggestionHeader() {
      return [
        {
          text: this.$t('fields.auditProgram'),
          value: 'audit_program_name'
        },
        {
          text: this.$t('fields.activityProfile'),
          value: 'audit_activity_profile_name'
        },
        {
          text: this.$t('fields.riskProfile'),
          value: 'audit_risk_profile_name'
        },
        {
          text: this.$t('fields.industry'),
          value: 'audit_industry_name'
        },
        {
          text: this.$t('fields.category'),
          value: 'audit_category_name'
        },
        {
          text: this.$t('fields.businessType'),
          value: 'audit_business_type_name'
        },
        {
          text: this.$t('fields.date'),
          value: 'date',
          hideFilter: true
        }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    trialBalanceHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chartOfAccountDetail_id.code',
          hideFilter: true
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chartOfAccountDetail_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.customerAccountType'),
          value: 'chartOfAccountDetail_id.customAccountType',
          hideFilter: true
        },
        {
          text: this.$t('fields.beginningBalance'),
          value: 'beginning_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.debit'),
          value: 'debit',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.credit'),
          value: 'credit',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.endingBalance'),
          value: 'ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.calculatedEndingBalance'),
          value: 'calculated_ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.different'),
          value: 'calculated_diff_ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        }
      ]
    },
    adjustmentLineHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail_code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail_name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.customerAccountType'),
          value: 'chart_of_account_detail_type',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.endingBalance'),
          value: 'ending_balance',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.debit'),
          value: 'total_debit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.credit'),
          value: 'total_credit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.auditEndingBalance'),
          value: 'audit_ending_balance',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: '',
          sortable: false,
          hideFilter: true,
          value: 'link'
        }
      ]
    },
    conclusionReportHeaders() {
      return [
        {
          text: this.$t('fields.taskName'),
          value: 'task_name'
        },
        {
          text: this.$t('fields.auditorReport'),
          value: 'is_audit_report',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.financeReport'),
          value: 'is_finance',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.managementReport'),
          value: 'is_management_report',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.followUpReport'),
          value: 'is_follow_up_report',
          check: true,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    taskItemHeader() {
      return [
        {
          text: this.$t('fields.taskName'),
          value: 'name'
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    memberItemHeader() {
      return [
        {
          text: this.$t('fields.memberName'),
          value: 'contact_id.name'
        },
        {
          text: this.$t('fields.position'),
          value: 'position.name'
        },
        {
          text: this.$t('fields.team'),
          value: 'team_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    teamItemHeader() {
      return [
        {
          text: this.$t('fields.team'),
          value: 'name'
        },
        {
          text: this.$t('fields.regionName'),
          value: 'teamRegion_id.region_id.name'
        },
        {
          text: this.$t('fields.divisionName'),
          value: 'teamRegion_id.division_id.name'
        },
        {
          text: this.$t('fields.subDivisionName'),
          value: 'teamRegion_id.sub_division_id.name'
        }
      ]
    },
    empItemHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'name'
        }
      ]
    },
    sumBeginningBalance() {
      return this.sumField('beginning_balance')
    },
    sumDebit() {
      return this.sumField('debit')
    },
    sumCredit() {
      return this.sumField('credit')
    },
    sumEndingBalance() {
      return this.sumField('ending_balance')
    },
    sumCalEndingBalance() {
      return this.sumField('calculated_ending_balance')
    },
    sumDiffEndingBalance() {
      return this.sumField('calculated_diff_ending_balance')
    },
    adjSumHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail__code'
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail__name'
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'sum_debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'sum_credit',
          accountFormat: true
        },
        {
          text: '',
          sortable: false,
          hideFilter: true,
          value: 'link'
        }
      ]
    },
    adjSumLineHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail__code'
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail__name'
        },
        {
          text: this.$t('fields.taskRef'),
          value: 'task_adjustment__task__reference'
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'task_adjustment__ref'
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          accountFormat: true
        },
        {
          text: '',
          sortable: false,
          hideFilter: true,
          value: 'task_adjustment__task__id'
        }
      ]
    },
    adjLineHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail__code'
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail__name'
        },
        {
          text: this.$t('fields.customerAccountType'),
          value: 'chart_of_account_detail__customAccountType'
        },
        {
          text: this.$t('fields.taskRef'),
          value: 'task_adjustment__task__reference'
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'task_adjustment__ref'
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'task_adjustment__adj_note'
        },
        {
          text: '',
          sortable: false,
          hideFilter: true,
          value: 'task_adjustment__task__id'
        }
      ]
    },
    timesheetByPositionHeaders() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'task_sequence',
          hideFilter: false,
          filterMode: 'exact',
          filterName: 'task__sequence'
        },
        {
          text: this.$t('fields.task'),
          value: 'task_name',
          hideFilter: false,
          filterName: 'task__name'
        },
        {
          text: this.$t('fields.position'),
          value: 'position_name',
          hideFilter: false,
          filterName: 'position__name'
        },
        {
          text: this.$t('fields.standardMD'),
          value: 'standard_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.unassigned'),
          value: 'unassigned',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedProgress'),
          value: 'assigned_progress',
          hideFilter: true,
          sortable: false,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'work_progress',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'weight',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    timesheetByMemberHeaders() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'member_name',
          hideFilter: false,
          filterName: 'audit_plan_member__contact_id__name'
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'total_expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'total_weight',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'total_finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'total_actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'total_variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'total_task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'total_work_progress',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    timesheetByMemberIdHeaders() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'task_sequence',
          hideFilter: false,
          filterMode: 'exact',
          filterName: 'task__sequence'
        },
        {
          text: this.$t('fields.task'),
          value: 'task_name',
          hideFilter: false,
          filterName: 'task__name'
        },
        {
          text: this.$t('fields.name'),
          value: 'member_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.position'),
          value: 'position_name',
          hideFilter: false,
          filterName: 'task_standard_man_day__position_id__name'
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'total_expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'total_weight',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'total_finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'total_actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'total_variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'total_task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'total_work_progress',
          hideFilter: true,
          percentFormat: true
        }
      ]
    }
  },
  watch: {
    'formData.contact_id': function (value) {
      this.isPublicCompany = false
      this.isLimitedPartnership = false
      this.isForeign = false
      this.isJointVenture = false
      if (value.prefix_id)
        this.$api({
          method: 'get',
          url: `contact/contact-prefix/${value.prefix_id.id}/`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.isPublicCompany = data.detail.is_public_company
          this.isLimitedPartnership = data.detail.is_limited_partnership
          this.isForeign = data.detail.is_foreign
          this.isJointVenture = data.detail.is_joint_venture
        })
    }
  },
  created() {
    this.auditHeaderId = this.$route.params.id || 0
  },
  methods: {
    accountingFormat: accounting,
    hoursMinutesFormat: hoursMinutes,
    percentFormat: percentFormat,
    prepareData(data) {
      data.chart_of_account_header_id = data?.chart_of_account_header_id?.id
      data.contact_id = data?.contact_id.id
      data.audit_plan_id = data?.audit_plan_id?.id
      data.trial_balance_header_id = data?.trial_balance_header_id?.id
      data.industry_id = data?.industry_id?.id
      data.business_cate_id = data?.business_cate_id?.id
      data.business_type_id = data?.business_type_id?.id
      data.prepare_by = data?.prepare_by?.id
      data.review_by = data?.review_by?.id
      return data
    },
    //----Audit Plan Function----
    getAuditPlanItemItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'sequence'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          audit_id: this.$route.params.id
        }
      } else {
        this.params = {
          audit_id: this.$route.params.id,
          ordering: 'sequence'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          // url: `task/task/?audit_id=${this.$route.params.id}`,
          url: `task/task/`,
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.auditPlanItemServerItemsLength = data.count
          this.auditPlanItemItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    onDeleteAuditTaskItemItem(item) {
      this.onDeleteAuditTaskItem(item)
    },
    onDeleteAuditTaskItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getAuditPlanItemItems()
        })
      })
      this.loading = false
    },
    getSuggestion() {
      this.$api({
        method: 'get',
        url: `/audit/audit-plan-suggest/?contact_id=${this.editedAuditPlan.contact_id.id}&suggest_type=${this.editedAuditPlan.suggest_type}`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.suggestionItemServerItemsLength = data.count
        this.suggestionItemItems = data.results
      })
    },
    async saveAuditPlanItem() {
      this.loading = true
      const data = {}
      data.audit_id = this.$route.params.id
      if (
        this.editedAuditPlan.contact_id !== null &&
        this.editedAuditPlan.contact_id !== undefined
      ) {
        data.contact_id = this.editedAuditPlan.contact_id.id
      }
      if (
        this.editedAuditPlan.suggest_type !== null &&
        this.editedAuditPlan.suggest_type !== undefined
      ) {
        data.suggest_type = this.editedAuditPlan.suggest_type
      }
      data.ids = this.selectedSuggest.map((value) => value.audit_program_id)

      await this.$api({
        method: this.apiMethod,
        url: `/audit/audit-suggest/`,
        data
      })

      this.loading = false
      this.getAuditPlanItemItems()
      this.closeAuditPlanItem()
    },
    closeAuditPlanItem() {
      this.editedAuditPlan = {}
      this.dialogAuditPlanItem = false
    },
    //----Trial Balance Functions----

    onChangeTrialBalance() {
      this.$refs.trialBalanceTable.reset()
    },
    async getTrialBalanceItems(options = null) {
      this.loading = true
      const trialBalanceHeader_id = this.formData?.trial_balance_header_id?.id
      const offset = (options.page - 1) * options.itemsPerPage
      const ordering = (
        options?.sortBy?.reduce(
          (acc, value, index) => [
            ...acc,
            `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
          ],
          []
        ) ?? ['chartOfAccountDetail_id__code']
      ).join(',')
      const params = {
        limit: options.itemsPerPage,
        offset: offset,
        search: options.search,
        ordering: ordering,
        ...options.multiSearch,
        trialBalanceHeader_id: trialBalanceHeader_id
      }

      if (
        trialBalanceHeader_id !== null &&
        trialBalanceHeader_id !== undefined
      ) {
        await this.$api({
          method: 'get',
          url: 'trial-balance/trial-balance-detail/',
          params: params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.trialBalanceItemsLength = data.count
          this.trialBalanceItems = data.results
          this.trialBalanceClientItems = data.results
        })
      }
      this.loading = false
    },
    getTrialBalanceClientItems(multiSearch) {
      this.trialBalanceClientItems = this.trialBalanceItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    accountFieldFormat(value) {
      if (value < 0) {
        const formatted = Math.abs(value)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return `(${formatted})`
      } else {
        return `${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
      }
    },

    sumField(key) {
      // sum data in give key (property)
      return this.trialBalanceItems.reduce(
        (a, b) => parseFloat(a) + parseFloat(b[key] || 0),
        parseFloat(0)
      )
    },
    getBusinessCateItem() {
      var url = ''
      if (
        this.formData.industry_id !== null &&
        this.formData.industry_id !== undefined
      ) {
        url =
          'doc-template/category/?active=true&industry_id=' +
          this.formData.industry_id.id +
          '&contact_id=' +
          this.formData.contact_id.id
      } else {
        url =
          'doc-template/category/?active=true' +
          '&contact_id=' +
          this.formData.contact_id.id
      }

      this.categoryUrl = url
    },
    getBusinessTypeItem() {
      var url = ''
      if (
        this.formData.business_cate_id !== null &&
        this.formData.business_cate_id !== undefined
      ) {
        url =
          'doc-template/buztype/?active=true&business_cate_id=' +
          this.formData.business_cate_id.id +
          '&contact_id=' +
          this.formData.contact_id.id
      } else {
        url =
          'doc-template/buztype/?active=true' +
          '&contact_id=' +
          this.formData.contact_id.id
      }
      this.buztypeUrl = url
    },

    //----Task Functions----
    getTasks(auditType = '') {
      this.loading = true
      const audit_id = this.formData.id
      if (audit_id) {
        this.$api({
          method: 'get',
          url: `/task/task/?audit_id=${audit_id}&${auditType}=true`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          if (auditType === 'task_template_header_id__is_pre_audit') {
            this.preAuditTasksLength = data.count
            this.preAuditTasks = data.results
          } else if (auditType === 'task_template_header_id__is_audit') {
            this.auditTasksLength = data.count
            this.auditTasks = data.results
          } else if (auditType === 'task_template_header_id__is_post_audit') {
            this.postAuditTasksLength = data.count
            this.postAuditTasks = data.results
          }
        })
      }
      this.loading = false
    },
    onCreateTask() {
      this.$router.push({
        name: 'taskCreate',
        query: {
          audit_plan_id: this.formData?.audit_plan_id?.id,
          audit_id: this.formData.id,
          from: 'audit-header'
        }
      })
    },
    onEditTask(item) {
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.id },
        query: {
          audit_plan_id: this.formData?.audit_plan_id?.id,
          audit_id: this.formData.id,
          from: 'audit-header'
        }
      })
    },
    onEditTaskReport(item) {
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.task_id },
        query: {
          audit_plan_id: this.formData?.audit_plan_id?.id,
          audit_id: this.formData.id,
          from: 'audit-header'
        }
      })
    },
    onDeleteTask(items, auditType = '') {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getTasks(auditType)
        })
      })
      this.loading = false
    },
    //----Members Functions----
    getTeams() {
      this.$api({
        method: 'get',
        url: `team/team-header/?state=approved`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.teamItems = data.results
      })
      this.loading = false
    },
    getEmployee() {
      this.$api({
        method: 'get',
        url: `contact/contact/?state=approved&is_employee=true`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.empItems = data.results
      })
      this.loading = false
    },
    getMemberItems() {
      const audit_header_id = this.$route.params.id
      if (audit_header_id) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-member/?audit_header_id=${audit_header_id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.memberItemsLength = data.count
          this.memberItems = data.results
        })
      }
      this.loading = false
    },
    closeMemberItem() {
      this.dialogMemberItem = false
      this.$nextTick(() => {
        this.editedMemberItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.editId = null
      this.selectedTeamEmpItems = []
      this.apiMethod = 'post'
      this.loading = false
    },
    async saveMemberItem() {
      this.loading = true
      const data = {
        audit_header_id: Number(this.$route.params.id),
        audit_plan_header_id: Number(this.formData.audit_plan_id.id)
      }

      if (this.editId) {
        if (this.editedMemberItem.contact_id) {
          data.contact_id = this.editedMemberItem.contact_id.id
        }
        data.position = this.editedMemberItem.position?.id
          ? this.editedMemberItem.position.id
          : this.editedMemberItem.position
      } else {
        data.type = this.editedMemberItem.type_member
        data.ids = this.selectedTeamEmpItems.map((value) => value.id)
      }
      await this.$api({
        method: this.apiMethod,
        url: `/audit/audit-plan-member/${this.editId ? this.editId + '/' : ''}`,
        data
      })
      this.loading = false
      this.getMemberItems()
      this.closeMemberItem()
    },
    onDeleteMemberItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-plan-member/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selectedMemberItems = []
          this.getMemberItems()
        })
      })
      this.loading = false
    },
    onEditMemberItem(item) {
      this.editedMemberItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogMemberItem = true
    },
    onChangeTypeMember(value) {
      this.editedMemberItem.type_member = value
      if (value === 'team') {
        this.getTeams()
      } else if (value === 'member') {
        this.getEmployee()
      }
    },
    async getAdjSumItems(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/${this.$route.params.id}/adj_rec_sum/`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjSumItems = data.results
        this.adjSumItemsCount = data.count

        this.adjSumGrandTotalDebit = data.grand_total_debit ?? 0
        this.adjSumGrandTotalCredit = data.grand_total_credit ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showAdjSumDialog(item) {
      this.editingAdjSum = Object.assign({}, item)
      this.dialogAdjSum = true
      this.$refs.adjSumLineTable.onServer()
    },
    onAdjSumLinesClose() {
      this.dialogAdjSum = false
      this.editingAdjSum = null
    },
    async getAdjSumLines(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/${this.$route.params.id}/adj_rec_sum/${this.editingAdjSum.chart_of_account_detail__code}/`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjSumLines = data.results
        this.adjSumLinesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getRiskMatrixItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-risk-matrix/?audit_header_id=${this.$route.params.id}&audit_plan_header_id=0`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.riskMatrixItemServerItemsLength = data.count
          this.riskMatrixItemItems = data.results
        })
      }
      this.loading = false
    },
    onViewRiskTask(item) {
      this.riskMatrixProfileId = item.id
      this.getRiskTask()
      this.dialogRiskMatrix = true
    },
    getRiskTask() {
      if (
        this.riskMatrixProfileId !== null &&
        this.riskMatrixProfileId !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-risk-matrix/detail/?risk_profile_id=${this.riskMatrixProfileId}&audit_header_id=${this.$route.params.id}&audit_plan_header_id=0`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.riskTaskItemServerItemsLength = data.count
          this.riskTaskItemItems = data.results
        })
      }
      this.loading = false
    },
    closeRiskMatrix() {
      this.riskTaskItemItems = []
      this.riskTaskItemServerItemsLength = 0
      this.dialogRiskMatrix = false
    },
    getActivityMatrixItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-activity-matrix/?audit_header_id=${this.$route.params.id}&audit_plan_header_id=0`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.activityMatrixItemServerItemsLength = data.count
          this.activityMatrixItemItems = data.results
        })
      }
      this.loading = false
    },
    onViewActivityTask(item) {
      this.activityMatrixProfileId = item.id
      this.getActivityTask()
      this.dialogActivityMatrix = true
    },
    getActivityTask() {
      if (
        this.activityMatrixProfileId !== null &&
        this.activityMatrixProfileId !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-activity-matrix/detail/?activity_profile_id=${this.activityMatrixProfileId}&audit_header_id=${this.$route.params.id}&audit_plan_header_id=0`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.activityTaskItemServerItemsLength = data.count
          this.activityTaskItemItems = data.results
        })
      }
      this.loading = false
    },
    closeActivityMatrix() {
      this.activityTaskItemItems = []
      this.activityTaskItemServerItemsLength = 0
      this.dialogActivityMatrix = false
    },
    async getTimesheetByPositions(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.audit_id = this.$route.params.id
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-position/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByPositions = data.results
        this.timesheetByPositionsCount = data.count

        this.timesheetByPositionSumStandardManDay = data.sum_standard_man_day
        this.timesheetByPositionSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByPositionSumUnassigned = data.sum_unassigned
        this.timesheetByPositionSumAssignedProgress = data.sum_assigned_progress
        this.timesheetByPositionSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByPositionSumActualManDay = data.sum_actual_man_day
        this.timesheetByPositionSumVariance = data.sum_variance
        this.timesheetByPositionSumTaskPerformance = data.sum_task_performance
        this.timesheetByPositionSumWorkProgress = data.sum_work_progress
        this.timesheetByPositionSumWeight = data.sum_weight
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getTimesheetByMembers(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.audit_id = this.$route.params.id
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-member/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByMembers = data.results
        this.timesheetByMembersCount = data.count
        this.timesheetByMemberSumStandardManDay = data.sum_standard_man_day
        this.timesheetByMemberSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByMemberSumWeight = data.sum_weight
        this.timesheetByMemberSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByMemberSumActualManDay = data.sum_actual_man_day
        this.timesheetByMemberSumVariance = data.sum_variance
        this.timesheetByMemberSumTaskPerformance = data.sum_task_performance
        this.timesheetByMemberSumWorkProgress = data.sum_work_progress
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getTimesheetByMemberIdItems(item, value, options) {
      if (!value || !options) return
      this.loading = true
      try {
        let params = defaultTableParams(options)

        params.audit_id = this.$route.params.id
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-member/${item.member_id}/`,
          params: params,
          hideSuccessAlert: true
        })
        this.timesheetByMemberIdItems[item.member_id] = data.results
        this.timesheetByMemberIdItemsCount[item.member_id] = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getReport() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        this.$api({
          method: 'get',
          url: `task/task-conclusion-report/?audit_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.conclusionReport = data
          this.conclusionReportCount = data.length
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    // -- adjustment function --
    getAdjustments() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        this.$api({
          method: 'get',
          url: `audit/audit-trial-before/?audit_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.adjustments = data.results
          this.adjustmentsCount = data.count

          this.adjGrandTotalDebit = data.grand_total_debit ?? 0
          this.adjGrandTotalCredit = data.grand_total_credit ?? 0
          this.adjGrandEndingBalance = data.grand_total_ending_balance ?? 0
          this.adjGrandAuditEndingBalance =
            data.grand_total_audit_ending_balance ?? 0
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showAdjDialog(item) {
      this.editingAdj = Object.assign({}, item)
      this.dialogAdj = true
      this.$refs.adjLineTable.onServer()
    },
    onAdjLinesClose() {
      this.dialogAdj = false
      this.editingAdj = null
    },
    async getAdjLines(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/${this.$route.params.id}/adj_rec_sum/${this.editingAdj.chart_of_account_detail_code}/?task_adjustment__is_before_audit=True`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjLines = data.results
        this.adjLinesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    // -- adjustment after function --
    getAdjustmentsAfter() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        this.$api({
          method: 'get',
          url: `audit/audit-trial-after/?audit_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.adjustmentsAfter = data.results
          this.adjustmentsAfterCount = data.count

          this.adjAfterGrandTotalDebit = data.grand_total_debit ?? 0
          this.adjAfterGrandTotalCredit = data.grand_total_credit ?? 0
          this.adjAfterGrandEndingBalance = data.grand_total_ending_balance ?? 0
          this.adjAfterGrandAuditEndingBalance =
            data.grand_total_audit_ending_balance ?? 0
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showAdjAfterDialog(item) {
      this.editingAdjAfter = Object.assign({}, item)
      this.dialogAdjAfter = true
      this.$refs.adjAfterLineTable.onServer()
    },
    onAdjAfterLinesClose() {
      this.dialogAdjAfter = false
      this.editingAdjAfter = null
    },
    async getAdjAfterLines(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/${this.$route.params.id}/adj_rec_sum/${this.editingAdjAfter.chart_of_account_detail_code}/?task_adjustment__is_after_audit=True`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjAfterLines = data.results
        this.adjAfterLinesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onPrintBalanceSheetCancel() {
      this.dialogPrintBalanceSheet = false
      this.$nextTick(() => {
        this.printBalanceSheet = {}
      })
    },
    async onPrintBalanceSheetConfirm() {
      this.dialogPrintBalanceSheet = false
      this.loading = true
      try {
        const response = await this.$api({
          url: `audit/report-balance-sheet/${this.$route.params.id}/`,
          hideSuccessAlert: true,
          hideErrorAlert: true,
          responseType: 'blob',
          params: {
            current_year_preferred_stock:
              this.printBalanceSheet.currentYearPreferredStock,
            current_year_common_stock:
              this.printBalanceSheet.currentYearCommonStock,
            last_year_preferred_stock:
              this.printBalanceSheet.lastYearPreferredStock,
            last_year_common_stock: this.printBalanceSheet.lastYearCommonStock,
            last_year_audit_id: this.printBalanceSheet.lastYearAuditId?.id,
            last_year_trial_balance_id:
              this.printBalanceSheet.lastYearTrialBalanceId?.id
          }
        })
        let href, link
        try {
          const type = response.headers['content-type']
          href = window.URL.createObjectURL(
            new Blob([response.data], {
              type: type,
              encoding: 'UTF-8'
            })
          )
          link = document.createElement('a')
          link.href = href
          // link.setAttribute('download', 'balance_sheet.pdf')
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
        } catch (e) {
          console.error(e)
        } finally {
          if (link) {
            document.body.removeChild(link)
          }
          if (href) {
            window.URL.revokeObjectURL(href)
          }
        }
      } catch (e) {
        console.error(e)
        const responseText = await e.data.text()
        if (responseText) {
          const response = JSON.parse(responseText)
          this.$alert('custom', {
            icon: 'error',
            title: this.$t('alert.validationError'),
            text: response['message']
          })
        }
      } finally {
        this.loading = false
      }
    },
    onPrintProfitAndLossCancel() {
      this.dialogPrintProfitAndLoss = false
      this.$nextTick(() => {
        this.printProfitAndLoss = {}
      })
    },
    async onPrintProfitAndLossConfirm() {
      this.dialogPrintProfitAndLoss = false
      this.loading = true
      try {
        const response = await this.$api({
          url: `audit/report-profit-and-loss/${this.$route.params.id}/`,
          hideSuccessAlert: true,
          hideErrorAlert: true,
          responseType: 'blob',
          params: {
            last_year_audit_id: this.printProfitAndLoss.lastYearAuditId?.id,
            last_year_trial_balance_id:
              this.printProfitAndLoss.lastYearTrialBalanceId?.id
          }
        })
        let href, link
        try {
          const type = response.headers['content-type']
          href = window.URL.createObjectURL(
            new Blob([response.data], {
              type: type,
              encoding: 'UTF-8'
            })
          )
          link = document.createElement('a')
          link.href = href
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
        } catch (e) {
          console.error(e)
        } finally {
          if (link) {
            document.body.removeChild(link)
          }
          if (href) {
            window.URL.revokeObjectURL(href)
          }
        }
      } catch (e) {
        console.error(e)
        const responseText = await e.data.text()
        if (responseText) {
          const response = JSON.parse(responseText)
          this.$alert('custom', {
            icon: 'error',
            title: this.$t('alert.validationError'),
            text: response['message']
          })
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
